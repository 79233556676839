const { generateSlug } = require('../helpers/generateSlug');

const sortingOptions = {
    nameAsc: 'Od A do Z',
    nameDesc: 'Od Z do A',
    priceAsc: 'Cena rosnąco',
    priceDesc: 'Cena malejąco'
};

exports.getFilterGraphQLPath = (filter) => {
    switch (filter) {
        case 'page':
            return 'alternative_id';
        case 'kinds':
            return 'kind';
        case 'bestsellers':
            return 'bestseller';
        case 'favorites':
            return 'alternative_id';
        case 'favoritesKnobs':
            return 'alternative_id';
        case 'lines':
            return 'lines.name';
        case 'brands':
            return 'lines.brands.name';
        case 'finishes':
            return 'lines.finishing';
        case 'colors':
            return ['variants', 'custom_variants'];
        case 'minimumPrice':
            return 'price';
        case 'maximumPrice':
            return 'price';
        case 'search':
            return 'name';
        case 'destinations':
            return 'purposes';
        case 'constructions':
            return 'construction';
        case 'glazes':
            return 'glaze';
        case 'fittings':
            return 'fitting';
        case 'styles':
            return 'style';
        case 'knobStyles':
            return 'filter_style';
        case 'knobColors':
            return 'filter_color';
        case 'handles':
            return 'handle_type';
        default:
            return filter;
    }
};

exports.getFilterGraphQLKey = (filter) => {
    switch (filter) {
        case 'colors':
            return 'color.color_filter_name';
        case 'destinations':
            return 'name';
        case 'finishes':
            return 'name';
        default:
            return null;
    }
};

exports.getFilterGraphQLComparator = (filter) => {
    switch (filter) {
        case 'page':
            return () => true;
        case 'minimumPrice':
            return (filterValue, doorValue) => +doorValue >= +filterValue;
        case 'maximumPrice':
            return (filterValue, doorValue) => +doorValue <= +filterValue;
        case 'search':
            return (filterValue, doorValue) => doorValue.startsWith(filterValue);
        case 'bestsellers':
            return (_filterValue, doorValue) => doorValue === '1';
        case 'favorites':
            return (_filterValue, doorValue) => {
                const values = JSON.parse(localStorage.getItem('BARANSKI_PRODUCTS_FAVORITES'));
                if (!values) return false;
                const favorites = values.favoriteDoors;
                if (!favorites) return false;
                return favorites[doorValue] === true;
            };
        case 'favoritesKnobs':
            return (_filterValue, doorValue) => {
                const values = JSON.parse(localStorage.getItem('BARANSKI_PRODUCTS_FAVORITES'));
                if (!values) return false;
                const favorites = values.favoriteAccesories;
                if (!favorites) return false;
                return favorites[doorValue] === true;
            };
        default:
            return (filterValue, doorValue) => filterValue.includes(doorValue);
    }
};

exports.getSortingFunction = (sorting) => {
    switch (sorting) {
        case sortingOptions.nameAsc:
            return (a, b) => a.name.localeCompare(b.name);
        case sortingOptions.nameDesc:
            return (a, b) => b.name.localeCompare(a.name);
        case sortingOptions.priceAsc:
            return (a, b) => a.price - b.price;
        case sortingOptions.priceDesc:
            return (a, b) => b.price - a.price;
        default:
            return null;
    }
};

const friendlyFiltersSet = {
    type: new Set(),
    brands: new Set(),
    lines: new Set(),
    colors: new Set(),
    finishes: new Set(),
    destinations: new Set(),
    styles: new Set(),
    kinds: new Set(),
    glazes: new Set(),
    fittings: new Set(),
    constructions: new Set(),
    bestsellers: new Set([
        'drzwi-wewnetrzne/bestsellers',
        'drzwi-zewnetrzne/bestsellers',
        'drzwi-techniczne/bestsellers'
    ])
};

const friendlyFiltersSetKnobs = {
    type: new Set(['klamki']),
    knobColors: new Set(),
    knobStyles: new Set(),
    handles: new Set(),
    bestsellers: new Set(['klamki/bestsellers'])
};

exports.addFiltersToSet = (door) => {
    const { type } = door.lines.brands;
    const brands = ['baranski-premium', 'baranski-smart', 'baranski-optimo'];
    // const brands = [door.lines.brands.name];
    const lines = [door.lines.name];
    const colors = [
        ...door.variants.map((c) => c.color.color_filter_name),
        ...door.custom_variants.map((c) => c.color.color_filter_name)
    ];
    const finishes = door.lines.finishing.map((c) => c.name);
    const destinations = door.purposes.map((p) => p.name);
    const {
        style, kind, glaze, fitting, construction
    } = door;
    const allFilters = {
        type,
        brands,
        lines,
        finishes,
        colors,
        destinations,
        styles: style,
        kinds: kind,
        glazes: glaze,
        fittings: fitting,
        constructions: construction
    };
    Object.keys(allFilters).forEach((key) => {
        const filter = allFilters[key];
        if (Array.isArray(filter)) filter.forEach((value) => friendlyFiltersSet[key].add(`${type}/${value}`));
        else friendlyFiltersSet[key].add(filter);
    });
};

exports.addFiltersToSetKnobs = (door) => {
    const type = 'klamki';
    const knobColors = door.filter_color;
    const knobStyles = door.filter_style;
    const handles = door.handle_type;
    const allFilters = {
        type,
        knobColors,
        knobStyles,
        handles
    };
    Object.keys(allFilters).forEach((key) => {
        const filter = allFilters[key];
        if (Array.isArray(filter)) filter.forEach((value) => friendlyFiltersSetKnobs[key].add(`${type}/${value}`));
        else friendlyFiltersSetKnobs[key].add(filter);
    });
};

exports.transformSetToFilters = () => Object.keys(friendlyFiltersSet).reduce(
    (acc, key) => ({
        ...acc,
        [key]: Array.from(friendlyFiltersSet[key]).map((filter) => filter
            .split('/')
            .map((brandAndFilter) => generateSlug(brandAndFilter))
            .join('/'))
    }),
    {}
);

exports.transformSetToFiltersKnobs = () => Object.keys(friendlyFiltersSetKnobs).reduce(
    (acc, key) => ({
        ...acc,
        [key]: Array.from(friendlyFiltersSetKnobs[key]).map((filter) => filter
            .split('/')
            .map((brandAndFilter) => generateSlug(brandAndFilter))
            .join('/'))
    }),
    {}
);

exports.getFilterContext = (filter, url) => {
    const [brand, value] = url.split('/');
    const text = Array.from(brand === 'klamki' ? friendlyFiltersSetKnobs[filter] : friendlyFiltersSet[filter])
        .map((val) => val.split('/')[1])
        .find((val) => generateSlug(val) === value);
    return {
        brand,
        friendlyFilter: value ? { name: filter, value, text } : undefined
    };
};
