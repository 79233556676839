import React, { useMemo, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'utilities/components/img/component';
import magnifyingGlass from 'assets/images/magnifying-glass.svg';
import { debounce } from 'lodash';
import { StringParam, useQueryParam } from 'use-query-params';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectBrand } from 'utilities/redux/productFiltersSlice';

export const SearchBox = ({ name, placeholder }) => {
    const [searchQuery, setSearchQuery] = useQueryParam('search', StringParam);
    const [pageParam, setPageParam] = useQueryParam('page', StringParam);
    const brand = useAppSelector(selectBrand);
    const redirect = (searchValue) => {
        setSearchQuery(searchValue || undefined);
        if (pageParam) setPageParam(undefined);
    };
    const debouncedChangeHandler = useMemo(() => debounce((searchValue) => redirect(searchValue), 500), []);
    const inputRef = useRef(null);

    useEffect(() => {
        if (searchQuery === undefined && inputRef.current && inputRef.current.value !== '') inputRef.current.value = '';
    }, [searchQuery]);

    if (brand === 'klamki') return null;
    return (
        <>
            <h4 className="filter__headline">Nazwa modelu: </h4>
            <label className="field__search" htmlFor={ name }>
                <Img className="search__icon" src={ magnifyingGlass } alt="lupa" />
                <input
                    className="search__input"
                    id={ name }
                    ref={ inputRef }
                    name={ name }
                    type="text"
                    placeholder={ placeholder }
                    defaultValue={ searchQuery }
                    onChange={ (event) => {
                        debouncedChangeHandler(event.target.value);
                    } }
                />
            </label>
        </>
    );
};

SearchBox.propTypes = {
    name: PropTypes.string,
    placeholder: PropTypes.string
};

SearchBox.defaultProps = {
    name: '',
    placeholder: ''
};
