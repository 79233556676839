import React from 'react';
import { Checkbox } from '../checkbox/view';
import baranskiPremium from 'assets/images/baranski-premium.svg';
import baranskiOptimo from 'assets/images/baranski-optimo.svg';
import baranskiSmart from 'assets/images/baranski-smart.svg';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectBrand, selectFriendlyFilter } from 'utilities/redux/productFiltersSlice';
import { navigate } from 'gatsby';
import {
    ArrayParam, StringParam, useQueryParam, withDefault
} from 'use-query-params';
import { getNewLink } from 'utilities/data/friendlyLinkParam';

export const Brands = () => {
    const [brandParam, setBrandParam] = useQueryParam('brands', withDefault(ArrayParam, []));
    const [pageParam, setPageParam] = useQueryParam('page', StringParam);
    const allSearchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;

    const friendlyFilter = useAppSelector(selectFriendlyFilter);
    const brand = useAppSelector(selectBrand);

    const handleCheck = (val) => {
        const action = getNewLink({
            param: brandParam,
            brand,
            allSearchParams,
            friendlyFilter,
            paramName: 'brands',
            val
        });
        if (pageParam) setPageParam(undefined);
        if (action.type === 'param') setBrandParam(action.url);
        else navigate(action.url);
    };

    if (friendlyFilter === null || brand === null || brand === 'klamki') return null;
    return (
        <div className="filter">
            <h4 className="filter__headline">Marka</h4>
            <Checkbox
                name="baranski-premium"
                onChange={ handleCheck }
                image={ baranskiPremium }
                defaultChecked={ brandParam.includes('baranski-premium') || friendlyFilter?.value === 'baranski-premium' }
            />
            <Checkbox
                name="baranski-optimo"
                onChange={ handleCheck }
                image={ baranskiOptimo }
                defaultChecked={ brandParam.includes('baranski-optimo') || friendlyFilter?.value === 'baranski-optimo' }
            />
            <Checkbox
                name="baranski-smart"
                onChange={ handleCheck }
                image={ baranskiSmart }
                defaultChecked={ brandParam.includes('baranski-smart') || friendlyFilter?.value === 'baranski-smart' }
            />
        </div>
    );
};
