import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { DoorView } from 'utilities/components/door/component/view';
import { Filters } from '../../filters/filters/view';
import './view.scss';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectAvailableFilters, selectBrand, selectFriendlyFilter } from 'utilities/redux/productFiltersSlice';
import { generateSlug } from 'utilities/helpers/generateSlug';
import { Pagination } from 'features/products/filters/pagination/view';
import { ArrayParam, useQueryParam, withDefault } from 'use-query-params';
import { HeadlineView } from 'utilities/components/headline/component/view';
import banner1 from 'assets/images/index/banner--1.jpg';
import { CardView } from 'features/products/card/component/view';
import { Swiper, SwiperSlide } from 'swiper/react';
import { ArticleView } from 'utilities/components/article/component/view';
import { ButtonsView } from 'utilities/components/buttons/component/view';
import { Labels } from 'features/products/filters/labels/view';
import { navigate } from 'gatsby';
import { paths } from 'utilities/routes';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import { throttle } from 'lodash';

export const ContentView = ({
    doors, numberOfPages, articles, title
}) => {
    const [colorParam] = useQueryParam('colors', withDefault(ArrayParam, []));
    const [filtersVisible, setFiltersVisible] = useState(false);
    const brand = useAppSelector(selectBrand);
    const friendlyFilter = useAppSelector(selectFriendlyFilter);
    const availableFilters = useAppSelector(selectAvailableFilters);
    const brandName = ['Drzwi wewnętrzne', 'Drzwi zewnętrzne', 'Drzwi techniczne', 'Klamki'].find(
        (v) => generateSlug(v) === brand
    );
    const allSearchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
    const searchParams = Array.from(allSearchParams?.keys() || []);
    const friendlyFilterName = friendlyFilter
        ? availableFilters[friendlyFilter.name]?.find((v) => generateSlug(v) === friendlyFilter.value)
        : '';

    const getDoorImageWithColorFilter = (door) => {
        if (!door) return undefined;
        const colorFilters = friendlyFilter?.name === 'colors' ? [...colorParam, friendlyFilter.value] : [...colorParam];
        const mainVariant = door.variants?.find((v) => v.main === 1) || door.custom_variants?.find((v) => v.main === 1) || door.image;
        if (!mainVariant) return '';
        if (!colorFilters || colorFilters.length === 0) return { main_model: mainVariant.main_model || mainVariant, second_model: mainVariant.second_model };
        const lineHasFilteredColor = door.lines.color
            .filter((color) => colorFilters.includes(generateSlug(color.color_filter_name)))
            .map((color) => color.name);
        if (!lineHasFilteredColor || lineHasFilteredColor.length === 0) return { main_model: mainVariant.main_model, second_model: mainVariant.second_model };
        const filteredColorDoorImage = door.variants
            .filter((color) => lineHasFilteredColor.includes(color.gallery_color))
            .filter((d) => d.main_model);
        if (!filteredColorDoorImage || filteredColorDoorImage.length === 0) return { main_model: mainVariant.main_model, second_model: mainVariant.second_model };
        const randomIndex = Math.floor(Math.random() * filteredColorDoorImage.length);
        const randomDoorColor = filteredColorDoorImage[randomIndex];
        return {
            main_model: randomDoorColor?.main_model || filteredColorDoorImage[0].main_model,
            second_model: randomDoorColor?.second_model
        };
    };

    const clearFilters = () => {
        if (brand) return navigate(`${paths.produkty}/${brand}`);
        return navigate(paths.produkty);
    };

    const setVisible = (val) => {
        setFiltersVisible(val);
        if (val) disableBodyScroll();
        else enableBodyScroll();
    };

    useEffect(() => {
        const handleResize = throttle(() => {
            if (window.matchMedia('(min-width: 768px)').matches) {
                setFiltersVisible(false);
                enableBodyScroll();
            }
        }, 100);

        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <section className="content">
            <Filters visible={ filtersVisible } setVisible={ setVisible } />
            <div className="content__container">
                <div className="controls__buttons">
                    <button className="controls__filter" type="button" onClick={ () => setVisible(!filtersVisible) }>
                        Filtruj
                    </button>
                    {(friendlyFilter || searchParams.length > 0) && (
                        <button className="controls__clear" type="button" onClick={ clearFilters }>
                            Wyczyść filtry
                        </button>
                    )}
                </div>
                <div className="controls__container">
                    <h1 className="content__headline">
                        {title}
                        {!title && brandName}
                        {!title && friendlyFilterName && ': '}
                        {!title && friendlyFilterName && <span className="filter-name">{friendlyFilterName}</span>}
                    </h1>
                    <Labels />
                </div>
                {doors.map((door) => (
                    <DoorView
                        image={ getDoorImageWithColorFilter(door) }
                        headline={ door.name }
                        collection={ door.lines?.name }
                        price={ door.price }
                        id={ door.alternative_id }
                        key={ door.alternative_id + door.name }
                        bestseller={ Boolean(door.bestseller) }
                        isAccessory={ brand === 'klamki' }
                    />
                ))}
                {doors.length === 0 && (
                    <p>Niestety nie ma produktów, które spełniają wszystkie kryteria. Usuń niektóre filtry.</p>
                )}
                {numberOfPages > 1 && <Pagination numberOfPages={ numberOfPages } />}
                <div className="content__articles">
                    <HeadlineView text="Sprawdź także" />
                    <Swiper
                        className="content__carousel"
                        navigation={{
                            nextEl: '.articles--next',
                            prevEl: '.articles--previous'
                        }}
                        centerInsufficientSlides
                        slidesPerView="1"
                        breakpoints={{
                            1280: {
                                slidesPerView: 'auto'
                            }
                        }}
                    >
                        {articles?.map((currentValue, index) => (
                            <SwiperSlide className="carousel__item" key={ index }>
                                <ArticleView
                                    alternative_id={ currentValue.alternative_id }
                                    image_main={ currentValue?.image_main }
                                    author={ currentValue?.author }
                                    created_at={ currentValue?.created_at }
                                    slug={ currentValue?.slug }
                                    title={ currentValue?.title }
                                    content={ currentValue?.lead }
                                    reading_time={ currentValue?.reading_time }
                                    link="/"
                                    isLarge
                                />
                            </SwiperSlide>
                        ))}
                        <ButtonsView name="articles" />
                    </Swiper>
                </div>
                <CardView
                    image={ banner1 }
                    headline="Jak wybrać drzwi by pasowały do Twojego domu?"
                    buttonText="Jakie drzwi wewnętrzne wybrać"
                    buttonLink="/artykul/jakie-drzwi-wewnetrzne-wybrac-15"
                    additionalButtonText="Jakie drzwi zewnętrzne wybrać"
                    additionalButtonLink="/artykul/jakie-drzwi-zewnetrzne-wybrac-do-domu-16"
                />
            </div>
        </section>
    );
};

ContentView.propTypes = {
    doors: PropTypes.instanceOf(Array).isRequired,
    articles: PropTypes.instanceOf(Array).isRequired,
    numberOfPages: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired
};
