import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';

import { Navigation } from 'utilities/components/navigation/component';
import { Footer } from 'utilities/components/footer/component';
import { MESSENGER_APP_ID, MESSENGER_PAGE_ID } from 'utilities/consts';
import MessengerCustomerChat from 'react-messenger-customer-chat';
import SEO from 'utilities/components/seo';
import { Content } from 'features/products/content/component';

import { Breadcrumbs } from 'utilities/components/breadcrumbs/view';
import { routes } from 'utilities/routes';

import banner1 from 'assets/images/index/banner--1.jpg';
import { useSeoTexts } from 'utilities/hooks/useSeoTexts';
import { SeoDecription } from 'features/index/seo-description/view';
import { useAppDispatch } from 'utilities/redux/reduxHooks';

import { setBrand, setFriendlyFilter } from 'utilities/redux/productFiltersSlice';

const Products = ({ location, pageContext }) => {
    const { brand, friendlyFilter } = pageContext;
    const page = new URLSearchParams(location.search).get('page');
    const {
        metaTitle, metaDescription, filterHeader, description
    } = useSeoTexts(brand, friendlyFilter);
    const typesList = {
        'drzwi-wewnetrzne': 'Drzwi wewnętrzne',
        'drzwi-zewnetrzne': 'Drzwi zewnętrzne',
        'drzwi-techniczne': 'Drzwi techniczne',
        klamki: 'Klamki'
    };

    const breadcrumbs = useMemo(
        () => [routes.home, brand ? { path: `/produkty/${brand}`, name: typesList[brand] } : routes.produkty],
        [brand, typesList]
    );

    const setCanonicalLink = () => {
        let relCanonical = '';
        if (typeof window !== 'undefined') {
            relCanonical = `${window.location.origin}${window.location.pathname}`;
        }
        return relCanonical;
    };
    const noIndexCase = friendlyFilter === 'bestsellers';
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setBrand(brand));
        dispatch(setFriendlyFilter(friendlyFilter));
    }, [dispatch, brand, friendlyFilter]);

    return (
        <main className="products">
            <Breadcrumbs breadcrumbs={ breadcrumbs } />
            <SEO
                title={
                    metaTitle
                    || `${typesList[brand] || ''} ${friendlyFilter?.text?.toLowerCase() || ''} | Barański Drzwi`
                }
                description={ metaDescription }
                image={ `https://www.baranskidrzwi.pl${banner1}` }
                alt={ typesList[brand] }
                canonicalLink={ setCanonicalLink() }
                robotsDirective={ !location.search && (!page || +page === 1) ? '' : 'noindex, follow' }
                noindex={ noIndexCase }
            />
            <Navigation />
            <Content brand={ brand } friendlyFilter={ friendlyFilter } title={ filterHeader } location={ location } />
            {/* { productsView() } */}
            <SeoDecription seoDescription={ description } />
            <Footer />
            <MessengerCustomerChat pageId={ MESSENGER_PAGE_ID } appId={ MESSENGER_APP_ID } />
        </main>
    );
};

export default Products;

Products.propTypes = {
    pageContext: PropTypes.shape({
        brand: PropTypes.string,
        friendlyFilter: PropTypes.shape({
            name: PropTypes.string,
            value: PropTypes.string,
            text: PropTypes.string,
            nestedPath: PropTypes.string,
            key: PropTypes.string
        })
    }).isRequired,
    location: PropTypes.shape({ search: PropTypes.string }).isRequired
};
