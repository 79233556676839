import { navigate } from 'gatsby';
import React from 'react';
import {
    ArrayParam, StringParam, useQueryParam, useQueryParams, withDefault
} from 'use-query-params';
import { getNewLink } from 'utilities/data/friendlyLinkParam';
import { selectBrand } from 'utilities/redux/productFiltersSlice';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import './view.scss';

export const Labels = () => {
    const brand = useAppSelector(selectBrand);
    const allSearchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
    const displayParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
    if (displayParams) displayParams.delete('page');
    const searchParams = Array.from(displayParams?.values() || []);
    const [pageParam, setPageParam] = useQueryParam('page', StringParam);

    const [query, setQuery] = useQueryParams(
        Object.fromEntries(Array.from(allSearchParams?.keys() || []).map((el) => [el, withDefault(ArrayParam, [])]))
    );

    const handleClick = (val) => {
        const paramName = Array.from(allSearchParams.entries()).find((arr) => arr.includes(val))[0];
        const action = getNewLink({
            param: query[paramName],
            paramName,
            allSearchParams,
            brand,
            val
        });
        if (pageParam) setPageParam(undefined);
        if (action.type === 'navigate') return navigate(action.url);
        return setQuery({ [paramName]: action.url });
    };
    return (
        <>
            {searchParams.length > 0
                && searchParams.map((param, index) => (
                    <button key={ param + index } className="badge" type="button" onClick={ () => handleClick(param) }>
                        {param.replace('-', ' ')}
                    </button>
                ))}
        </>
    );
};
