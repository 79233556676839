import React, { useState } from 'react';
import { Checkbox } from '../checkbox/view';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectAvailableFilters, selectBrand, selectFriendlyFilter } from 'utilities/redux/productFiltersSlice';
import { navigate } from 'gatsby';
import {
    ArrayParam, StringParam, useQueryParam, withDefault
} from 'use-query-params';
import { getNewLink } from 'utilities/data/friendlyLinkParam';
import PropTypes from 'prop-types';
import { generateSlug } from 'utilities/helpers/generateSlug';
import classNames from 'classnames';

export const CheckboxFilters = ({ paramName, title }) => {
    const [param, setParam] = useQueryParam(paramName, withDefault(ArrayParam, []));
    const [pageParam, setPageParam] = useQueryParam('page', StringParam);
    const [expanded, setExpanded] = useState(false);
    const allSearchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;

    const friendlyFilter = useAppSelector(selectFriendlyFilter);
    const brand = useAppSelector(selectBrand);
    const availableFilters = useAppSelector(selectAvailableFilters)[paramName];

    const handleCheck = (val) => {
        const action = getNewLink({
            param,
            brand,
            allSearchParams,
            friendlyFilter,
            paramName,
            val: generateSlug(val)
        });
        if (pageParam) setPageParam(undefined);
        if (action.type === 'param') setParam(action.url);
        else navigate(action.url);
    };

    const divCls = classNames('filter', { 'filter--active': expanded });

    if (friendlyFilter === null || brand === null) return null;
    if (availableFilters === undefined || availableFilters.length === 0) return null;
    return (
        <div className={ divCls }>
            <h4 className="filter__headline">{title}</h4>
            {availableFilters.slice(0, expanded ? undefined : 5).map((filter, i) => (
                <Checkbox
                    key={ filter + i }
                    name={ filter }
                    onChange={ handleCheck }
                    defaultChecked={
                        param.includes(generateSlug(filter)) || friendlyFilter?.value === generateSlug(filter)
                    }
                />
            ))}
            {availableFilters.length > 5 && (
                <button className="filter__button" type="button" onClick={ () => setExpanded(!expanded) }>
                    {expanded ? 'Zobacz mniej' : 'Zobacz więcej'}
                </button>
            )}
        </div>
    );
};

CheckboxFilters.propTypes = {
    paramName: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
};
