import React from 'react';

import { sortingOptions } from 'utilities/data/doors';
import { SelectParam } from '../select/select-param/view';
import { SearchBox } from '../search-box/view';
import { SelectType } from '../select/select-type/view';
import { Brands } from '../brands/view';
import { FiltersHeading } from '../heading/view';
import { SelectLine } from '../select/select-line/view';
import { FavoritesFilter } from '../favorites/view';
import { CheckboxFilters } from '../checkbox-filters/view';
import { PriceBox } from '../price-box/view';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Img } from 'utilities/components/img/component';
import cross from 'assets/images/cross.svg';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectBrand } from 'utilities/redux/productFiltersSlice';

export const Filters = ({ visible, setVisible }) => {
    const sorters = Object.values(sortingOptions);
    const brands = ['drzwi wewnętrzne', 'drzwi zewnętrzne', 'drzwi techniczne', 'klamki'];
    const brand = useAppSelector(selectBrand);
    return (
        <div className={ classNames('filters', { 'filters--active': visible }) }>
            <button className="filters__close" type="button" onClick={ () => setVisible(false) }>
                <Img className="filters__icon" src={ cross } alt="iks" />
            </button>
            <FiltersHeading setVisible={ setVisible } />
            <SearchBox name="search" placeholder="Szukaj..." />
            <SelectParam title="Sortowanie" name="wybierz rodzaj sortowania" param="sorting" values={ sorters } />
            <SelectType title="Typ produktu" name="wybierz rodzaj drzwi" values={ brands } />
            <Brands />
            <SelectLine />
            <FavoritesFilter />
            <CheckboxFilters paramName="kinds" title="Rodzaj" />
            <CheckboxFilters paramName="handles" title="Rodzaj" />
            <CheckboxFilters paramName="finishes" title="Wykończenie" />
            <CheckboxFilters paramName={ brand === 'klamki' ? 'knobColors' : 'colors' } title="Kolor" />
            <CheckboxFilters paramName={ brand === 'klamki' ? 'knobStyles' : 'styles' } title="Styl" />
            <CheckboxFilters paramName="glazes" title="Szklenia" />
            <CheckboxFilters paramName="constructions" title="Konstrukcja" />
            <CheckboxFilters paramName="fittings" title="Okucia" />
            <CheckboxFilters paramName="destinations" title="Przeznaczenie" />
            <PriceBox />
        </div>
    );
};

Filters.propTypes = {
    visible: PropTypes.bool,
    setVisible: PropTypes.func.isRequired
};

Filters.defaultProps = {
    visible: false
};
