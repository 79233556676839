import React, { useEffect, useState } from 'react';
import { StringParam, useQueryParam, withDefault } from 'use-query-params';
import PropTypes from 'prop-types';
import './view.scss';

export const Pagination = ({ numberOfPages }) => {
    const [pageParam, setPageParam] = useQueryParam('page', withDefault(StringParam, '1'));
    const [paginationArray, setPaginationArray] = useState([]);

    const updatePaginationList = () => {
        // if there's just 1 page, pagination component is not displayed

        // case for total of 2 pages
        if (+numberOfPages === 2) {
            // case for page 1
            if (+pageParam === 1) {
                setPaginationArray([+pageParam, +pageParam + 1]);
            }
            // case for page 1
            if (+pageParam === 2) {
                setPaginationArray([+pageParam - 1, +pageParam]);
            }
        }

        if (+numberOfPages >= 3) {
            // case for page 1
            if (+pageParam === 1) {
                setPaginationArray([+pageParam, +pageParam + 1, +pageParam + 2]);
            }
            // case for pages between 1 - last
            if (+pageParam > 1 && +pageParam < numberOfPages) {
                setPaginationArray([+pageParam - 1, +pageParam, +pageParam + 1]);
            }
            // case for last page
            if (+pageParam === numberOfPages) {
                setPaginationArray([+numberOfPages - 2, +numberOfPages - 1, +numberOfPages]);
            }
        }
    };

    useEffect(() => {
        updatePaginationList();
    }, []);

    useEffect(() => {
        updatePaginationList();
    }, [pageParam, numberOfPages]);

    return (
        <div className="pagination">
            <div className="pagination__container">
                {`${pageParam} z ${numberOfPages} stron`}

                <div className="pagination__progress">
                    <div className="progress__line" style={{ width: `${(+pageParam / +numberOfPages) * 100}%` }} />
                </div>

                <div className="pagination__container-navigation">
                    <button
                        type="button"
                        onClick={ () => {
                            setPageParam('1');
                        } }
                        className={ pageParam === '1' ? 'faded' : 'active' }
                    >
                        &#171;
                    </button>
                    <button
                        type="button"
                        onClick={ () => {
                            setPageParam(+pageParam - 1 || 1);
                        } }
                        className={ pageParam === '1' ? 'faded' : 'active' }
                    >
                        &#8249;
                    </button>
                    <div>
                        {paginationArray.map((pageNumber) => (
                            <button
                                type="button"
                                key={ pageNumber }
                                onClick={ () => setPageParam(pageNumber) }
                                className={
                                    +pageParam === +pageNumber
                                        ? 'current-page pagination__button'
                                        : 'pagination__button'
                                }
                            >
                                {+pageNumber}
                            </button>
                        ))}
                    </div>
                    <button
                        type="button"
                        onClick={ () => setPageParam(+pageParam + 1 > numberOfPages ? pageParam : +pageParam + 1) }
                        className={ +pageParam === numberOfPages ? 'faded' : 'active' }
                    >
                        &#8250;
                    </button>
                    <button
                        type="button"
                        onClick={ () => setPageParam(numberOfPages) }
                        className={ +pageParam === +numberOfPages ? 'faded' : 'active' }
                    >
                        &#187;
                    </button>
                </div>
            </div>
        </div>
    );
};

Pagination.propTypes = {
    numberOfPages: PropTypes.number.isRequired
};
