import React from 'react';
import { Img } from 'utilities/components/img/component';
import arrowIcon from 'assets/images/arrow--black.svg';
import Select, { components } from 'react-select';
import {
    ArrayParam, StringParam, useQueryParam, withDefault
} from 'use-query-params';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectAvailableFilters, selectBrand, selectFriendlyFilter } from 'utilities/redux/productFiltersSlice';
import { getNewLink, removeMultipleFromLink } from 'utilities/data/friendlyLinkParam';
import { navigate } from 'gatsby';
import { generateSlug } from 'utilities/helpers/generateSlug';

const DropdownIndicator = (props) => (
    <components.DropdownIndicator { ...props }>
        <Img
            src={ arrowIcon }
            // eslint-disable-next-line react/prop-types, react/destructuring-assignment
            className={ `dropdownIndicator ${props.selectProps.menuIsOpen && 'dropdownIndicatorOpen'}` }
            alt=""
        />
    </components.DropdownIndicator>
);

const ClearIndicator = (props) => (
    <components.ClearIndicator { ...props }>
        <div className="clearIndicator" />
    </components.ClearIndicator>
);

const MultiValueRemove = (props) => (
    <components.MultiValueRemove { ...props }>
        <div className="multiValueRemove" />
    </components.MultiValueRemove>
);

const customStyles = {
    control: (provided) => ({
        ...provided,
        border: '1px solid #d4d4d4',
        borderRadius: 0,
        boxShadow: 'none',
        fontSize: '.8rem',
        padding: '8px 5px 8px 16px',
        '&:hover': { border: '1px solid #d4d4d4' }
    }),
    valueContainer: (provided) => ({
        ...provided,
        margin: '0',
        padding: '0'
    }),
    placeholder: (provided) => ({
        ...provided,
        color: '#A9A9A9'
    }),
    menu: (provided) => ({
        ...provided,
        borderRadius: 0,
        margin: '0 1px 0 1px',
        width: 'calc(100% - 2px)'
    }),
    option: () => ({
        fontSize: '.8rem',
        padding: '15px 20px',
        backgroundColor: 'transparent',
        borderBottom: '1px solid #d4d4d4',
        cursor: 'pointer',
        '&:last-child': { borderBottom: '0px solid #d4d4d4' }
    }),
    multiValue: (provided) => ({
        ...provided,
        borderRadius: '5px',
        padding: '2px 7px'
    }),
    multiValueRemove: (provided) => ({
        ...provided,
        fontSize: '1.8rem',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: 'rgb(230, 230, 230)',
            color: 'black'
        }
    })
};

export const SelectLine = () => {
    const { lines } = useAppSelector(selectAvailableFilters);
    const [linesParam, setLinesParam] = useQueryParam('lines', withDefault(ArrayParam, []));
    const [pageParam, setPageParam] = useQueryParam('page', StringParam);
    const friendlyFilter = useAppSelector(selectFriendlyFilter);
    const selectedLines = friendlyFilter?.name === 'lines' ? [friendlyFilter.value] : linesParam;
    const allSearchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
    const brand = useAppSelector(selectBrand);

    const handleLinkChange = (val) => {
        const action = getNewLink({
            param: linesParam,
            brand,
            allSearchParams,
            friendlyFilter,
            paramName: 'lines',
            val: generateSlug(val)
        });
        if (pageParam) setPageParam(undefined);
        if (action.type === 'param') setLinesParam(action.url);
        else navigate(action.url);
    };

    const handleClearChange = () => {
        const action = removeMultipleFromLink({
            param: linesParam,
            brand,
            allSearchParams,
            friendlyFilter,
            paramName: 'lines'
        });
        if (action.type === 'param') setLinesParam(action.url);
        else navigate(action.url);
    };

    if (lines.length === 0) return null;
    return (
        <>
            <h4 className="filter__headline">Linie</h4>
            <Select
                options={ lines.map((line) => ({ value: line, label: line.toLowerCase() })) }
                value={ lines
                    .filter((l) => selectedLines.includes(generateSlug(l)))
                    .map((line) => ({ value: line, label: line.toLowerCase() })) }
                isMulti
                styles={ customStyles }
                onChange={ (_newValue, actionMeta) => {
                    if (actionMeta?.action === 'select-option') {
                        handleLinkChange(actionMeta?.option?.value);
                    } else if (actionMeta?.action === 'remove-value') {
                        handleLinkChange(actionMeta?.removedValue?.value);
                    } else if (actionMeta?.action === 'clear') {
                        handleClearChange();
                    }
                } }
                components={{
                    ClearIndicator,
                    DropdownIndicator,
                    MultiValueRemove
                }}
                placeholder="Wyszukaj linię"
                noOptionsMessage={ () => <span>Brak wyników wyszukiwania...</span> }
            />
        </>
    );
};
