import { debounce } from 'lodash';
import React, { useMemo } from 'react';
import { StringParam, useQueryParam } from 'use-query-params';

export const PriceBox = () => {
    const [fromParam, setFromParam] = useQueryParam('minimumPrice', StringParam);
    const [toParam, setToParam] = useQueryParam('maximumPrice', StringParam);
    const redirectFrom = (searchValue) => setFromParam(searchValue || undefined);
    const debouncedFrom = useMemo(() => debounce((searchValue) => redirectFrom(searchValue), 500), []);
    const redirectTo = (searchValue) => setToParam(searchValue || undefined);
    const debouncedTo = useMemo(() => debounce((searchValue) => redirectTo(searchValue), 500), []);

    return (
        <>
            <h4 className="filter__headline">Cena </h4>
            <div className="filter__range">
                <label className="field__common" htmlFor="Cena od">
                    <input
                        className="common__input"
                        id="Cena od"
                        name="Cena od"
                        type="text"
                        placeholder="Od"
                        defaultValue={ fromParam }
                        onChange={ (event) => {
                            debouncedFrom(event.target.value);
                        } }
                    />
                </label>
                <label className="field__common" htmlFor="Cena do">
                    <input
                        className="common__input"
                        id="Cena do"
                        name="Cena do"
                        type="text"
                        placeholder="Do"
                        defaultValue={ toParam }
                        onChange={ (event) => {
                            debouncedTo(event.target.value);
                        } }
                    />
                </label>
            </div>
        </>
    );
};
