import { navigate } from 'gatsby';
import React from 'react';
import {
    ArrayParam, StringParam, useQueryParam, withDefault
} from 'use-query-params';
import { getNewLink } from 'utilities/data/friendlyLinkParam';
import { selectBrand, selectFriendlyFilter } from 'utilities/redux/productFiltersSlice';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { Checkbox } from '../checkbox/view';

export const FavoritesFilter = () => {
    const brand = useAppSelector(selectBrand);
    const [favoritesParam, setFavoritesParam] = useQueryParam(
        brand === 'klamki' ? 'favoritesKnobs' : 'favorites',
        withDefault(ArrayParam, [])
    );
    const [pageParam, setPageParam] = useQueryParam('page', StringParam);
    const [bestsellerParam, setBestsellerParam] = useQueryParam('bestsellers', withDefault(ArrayParam, []));
    const allSearchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
    const friendlyFilter = useAppSelector(selectFriendlyFilter);

    const isFavorite = favoritesParam.includes('moje-ulubione');
    const isBestseller = bestsellerParam.includes('bestsellers') || friendlyFilter?.name === 'bestsellers';

    const handleFavorites = () => {
        if (pageParam) setPageParam(undefined);
        setFavoritesParam(isFavorite ? undefined : 'moje-ulubione');
    };
    const handleBestsellers = () => {
        const action = getNewLink({
            param: bestsellerParam,
            brand,
            allSearchParams,
            friendlyFilter,
            paramName: 'bestsellers',
            val: 'bestsellers'
        });
        if (pageParam) setPageParam(undefined);
        if (action.type === 'param') setBestsellerParam(action.url);
        else navigate(action.url);
    };

    return (
        <>
            <h4 className="filter__headline">Moje ulubione</h4>
            <Checkbox
                name="favorites"
                text="moje ulubione"
                isFavorite
                onChange={ handleFavorites }
                defaultChecked={ isFavorite }
            />
            <h4 className="filter__headline">Najpopularniejsze</h4>
            <Checkbox
                name="bestsellers"
                text="bestsellers"
                isBestseller
                onChange={ handleBestsellers }
                defaultChecked={ isBestseller }
            />
        </>
    );
};
