import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'utilities/components/img/component';
import classnames from 'classnames';
import arrow from 'assets/images/arrow--black.svg';
import { generateSlug } from 'utilities/helpers/generateSlug';
import { navigate } from 'gatsby';
import { paths } from 'utilities/routes';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectBrand } from 'utilities/redux/productFiltersSlice';

export const SelectType = ({ title, name, values }) => {
    const brand = useAppSelector(selectBrand);
    const brandName = values.find((v) => brand === generateSlug(v));
    const [isExpanded, setIsExpanded] = useState(false);

    const handleBrandChange = (val) => {
        navigate(`${paths.produkty}/${generateSlug(val)}`);
    };

    if (brand === 'klamki') return null;
    return (
        <>
            <h4 className="filter__headline">{title}</h4>
            <div className={ classnames('field__select', { 'field__select--active': isExpanded }) }>
                <button
                    className={ classnames('select__ballot-box', {
                        'select__ballot-box--colored': brand
                    }) }
                    type="button"
                    onClick={ () => setIsExpanded(!isExpanded) }
                >
                    {brandName || name}
                    <Img className="select__icon" src={ arrow } alt="strzałka" />
                </button>
                <div>
                    <ul className="select__list">
                        {values.map((v, index) => (
                            <li className="select__item" key={ index }>
                                <button className="select__button" type="button" onClick={ () => handleBrandChange(v) }>
                                    {v}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

SelectType.propTypes = {
    name: PropTypes.string,
    title: PropTypes.string,
    values: PropTypes.instanceOf(Array)
};

SelectType.defaultProps = {
    name: '',
    title: '',
    values: []
};
