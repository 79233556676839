import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Img } from 'utilities/components/img/component';

export const Checkbox = ({
    image, name, text, isFavorite, isBestseller, onChange, defaultChecked
}) => {
    const [checked, setChecked] = useState(defaultChecked || false);
    return (
        <label
            className={ classnames('field__checkbox', {
                'field__checkbox--brand': image
            }) }
            htmlFor={ name }
        >
            <input
                className="checkbox__input"
                id={ name }
                name={ name }
                type="checkbox"
                checked={ defaultChecked }
                onChange={ () => {
                    setChecked(!checked);
                    onChange(name);
                } }
            />
            <div
                className={ classnames(
                    'checkbox__ballot-box',
                    { 'checkbox__ballot-box--favorite': isFavorite && !isBestseller },
                    { 'checkbox__ballot-box--bestseller': isBestseller && !isFavorite }
                ) }
            />
            {image ? (
                <Img className="checkbox__logo" src={ image } alt="logo" />
            ) : (
                <p className="checkbox__text checkbox__text--favorites">{text || name}</p>
            )}
        </label>
    );
};

Checkbox.propTypes = {
    image: PropTypes.string,
    text: PropTypes.string,
    name: PropTypes.string.isRequired,
    isFavorite: PropTypes.bool,
    isBestseller: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    defaultChecked: PropTypes.bool
};

Checkbox.defaultProps = {
    image: '',
    text: '',
    isFavorite: false,
    isBestseller: false,
    defaultChecked: false
};
