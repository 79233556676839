const { paths } = require('utilities/routes');

export const getNewLink = ({
    param, paramName, brand, allSearchParams, friendlyFilter, val
}) => {
    const valExists = param.includes(val) || (friendlyFilter?.name === paramName && friendlyFilter?.value === val);
    const searchParams = Array.from(allSearchParams.keys());
    const unfriendlyParams = [
        'page',
        'sorting',
        'search',
        'minimumPrice',
        'maximumPrice',
        'favorites',
        'favoritesKnobs'
    ];
    const unfriendlyUsed = [...searchParams].filter((p) => unfriendlyParams.includes(p));
    const switchFromParamsToFriendlyLink = () => {
        const paramLeft = [...searchParams].filter((p) => !unfriendlyParams.includes(p));
        if (paramLeft.indexOf(paramName) !== -1) paramLeft.splice(paramLeft.indexOf(paramName), 1);
        if (unfriendlyUsed.indexOf(paramName) !== -1) unfriendlyUsed.splice(unfriendlyUsed.indexOf(paramName), 1);
        if (paramLeft.length !== 1) return { type: 'param', url: param.filter((v) => v !== val) };
        const doubleBrandParam = allSearchParams.getAll(paramLeft);
        if (doubleBrandParam.length === 2 && doubleBrandParam.indexOf(val) !== -1) doubleBrandParam.splice(doubleBrandParam.indexOf(val), 1);
        const unfriendly = unfriendlyUsed.length === 1 ? `?${unfriendlyUsed[0]}=${allSearchParams.get(unfriendlyUsed[0])}` : '';
        return { type: 'navigate', url: `${paths.produkty}/${brand}/${doubleBrandParam}${unfriendly}` };
    };
    if (valExists) {
        if (!brand) return { type: 'param', url: param.filter((v) => v !== val) };
        if (friendlyFilter) return { type: 'navigate', url: `${paths.produkty}/${brand}` };
        if (searchParams.length === 2) return switchFromParamsToFriendlyLink();
        if (searchParams.length === 3 && searchParams.some((sp) => unfriendlyParams.includes(sp))) return switchFromParamsToFriendlyLink();
        return { type: 'param', url: param.filter((v) => v !== val) };
    }
    if (!brand) return { type: 'param', url: [...param, val] };
    if (friendlyFilter) {
        const unfriendly = unfriendlyUsed
            .filter((p) => p !== 'page')
            .map((unfriendlyP) => allSearchParams
                .getAll(unfriendlyP)
                .map((unfriendlyVal) => `${unfriendlyP}=${unfriendlyVal}`)
                .join('&'))
            .join('&');
        const unfriendlyString = unfriendly ? `&${unfriendly}` : '';
        return {
            type: 'navigate',
            url: `${paths.produkty}/${brand}?${friendlyFilter.name}=${friendlyFilter.value}&${paramName}=${val}${unfriendlyString}`
        };
    }
    if (searchParams.length === 0) return { type: 'navigate', url: `${paths.produkty}/${brand}/${val}` };
    return { type: 'param', url: [...param, val] };
};

export const removeMultipleFromLink = ({
    brand, allSearchParams, paramName, friendlyFilter, param
}) => {
    const searchParams = Array.from(allSearchParams.keys());
    const switchFromParamsToFriendlyLink = () => {
        const paramLeft = searchParams.filter((p) => paramName !== p);
        const paramValue = allSearchParams.get(paramLeft);
        return { type: 'navigate', url: `${paths.produkty}/${brand}/${paramValue}` };
    };
    if (!brand) return { type: 'param', url: undefined };
    if (friendlyFilter) return { type: 'navigate', url: `${paths.produkty}/${brand}` };
    if (searchParams.length === param.length + 1) return switchFromParamsToFriendlyLink();
    return { type: 'param', url: undefined };
};
