import { navigate } from 'gatsby';
import React from 'react';
import { selectBrand, selectFriendlyFilter } from 'utilities/redux/productFiltersSlice';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { paths } from 'utilities/routes';
import PropTypes from 'prop-types';

export const FiltersHeading = ({ setVisible }) => {
    const brand = useAppSelector(selectBrand);
    const friendlyFilter = useAppSelector(selectFriendlyFilter);
    const allSearchParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : undefined;
    const searchParams = Array.from(allSearchParams?.keys() || []);

    const clearFilters = () => {
        if (brand) return navigate(`${paths.produkty}/${brand}`);
        return navigate(paths.produkty);
    };
    return (
        <div className="filters__container">
            <h4 className="filters__headline">Filtruj</h4>
            <button className="filters__show" type="button" onClick={ () => setVisible(false) }>
                Pokaż wyniki
            </button>
            {(friendlyFilter || searchParams.length > 0) && (
                <button className="filters__clear" type="button" onClick={ clearFilters }>
                    Wyczyść filtry
                </button>
            )}
        </div>
    );
};

FiltersHeading.propTypes = {
    setVisible: PropTypes.func.isRequired
};
