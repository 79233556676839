import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Img } from 'utilities/components/img/component';
import classnames from 'classnames';
import arrow from 'assets/images/arrow--black.svg';
import { StringParam, useQueryParam } from 'use-query-params';
import { useAppSelector } from 'utilities/redux/reduxHooks';
import { selectBrand } from 'utilities/redux/productFiltersSlice';

export const SelectParam = ({
    title, name, param, values
}) => {
    const [isExpanded, setIsExpanded] = useState(false);
    const [searchParam, setSearchParam] = useQueryParam(param, StringParam);
    const [pageParam, setPageParam] = useQueryParam('page', StringParam);
    const brand = useAppSelector(selectBrand);

    if (brand === 'klamki') return null;
    return (
        <>
            <h4 className="filter__headline">{title}</h4>
            <div className={ classnames('field__select', { 'field__select--active': isExpanded }) }>
                <button
                    className={ classnames('select__ballot-box', {
                        'select__ballot-box--colored': searchParam
                    }) }
                    type="button"
                    onClick={ () => setIsExpanded(!isExpanded) }
                >
                    {searchParam || name}
                    <Img className="select__icon" src={ arrow } alt="strzałka" />
                </button>
                <div>
                    <ul className="select__list">
                        {values.map((currentValue, index) => (
                            <li className="select__item" key={ index }>
                                <button
                                    className="select__button"
                                    type="button"
                                    onClick={ () => {
                                        setSearchParam(currentValue);
                                        if (pageParam) setPageParam(undefined);
                                        setIsExpanded(false);
                                    } }
                                >
                                    {currentValue}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </>
    );
};

SelectParam.propTypes = {
    name: PropTypes.string,
    param: PropTypes.string.isRequired,
    title: PropTypes.string,
    values: PropTypes.instanceOf(Array)
};

SelectParam.defaultProps = {
    name: '',
    title: '',
    values: []
};
