import React, {
    useState, useEffect, useCallback, useMemo
} from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import PropTypes from 'prop-types';
import { generateSlug } from 'utilities/helpers/generateSlug';
import {
    getFilterGraphQLComparator,
    getFilterGraphQLKey,
    getFilterGraphQLPath,
    getSortingFunction
} from 'utilities/data/friendlyFilters';
import _ from 'lodash';
import { ContentView } from './new-view';
import { useAppDispatch } from 'utilities/redux/reduxHooks';
import { setAvailableFilters } from 'utilities/redux/productFiltersSlice';

const DOORS_PER_PAGE = 32;

export const Content = ({
    brand, friendlyFilter, title, location
}) => {
    const data = useStaticQuery(graphql`
        query {
            allDoors(filter: { is_active: { eq: 1 } }) {
                nodes {
                    alternative_id
                    name
                    lines {
                        name
                        brands {
                            name
                            type
                        }
                        color {
                            name
                            color_filter_name
                        }
                        finishing {
                            name
                        }
                    }
                    style
                    purposes {
                        name
                    }
                    price
                    price_type
                    variants {
                        main
                        main_model
                    }
                    bestseller
                    variants {
                        color {
                            color_filter_name
                        }
                        line_variant {
                            name
                        }
                        gallery_variant
                        gallery_color
                        main_model
                        second_model
                        main
                    }
                    custom_variants {
                        gallery_variant
                        gallery_color
                        main_model
                        second_model
                        main
                        color {
                            color_filter_name
                        }
                    }
                    kind
                    glaze
                    fitting
                    construction
                }
            }
            allAccessories(filter: { id: { ne: "dummy" } }) {
                nodes {
                    alternative_id
                    image
                    name
                    brands {
                        name
                        type
                    }
                    filter_style
                    type_filter
                    price
                    handle_type
                    filter_color
                    bestseller
                }
            }
            allArticles(filter: { is_active: { eq: 1 } }) {
                nodes {
                    alternative_id
                    author
                    created_at(formatString: "DD.MM.YYYY")
                    image_main
                    lead
                    subtype
                    title
                    type
                    slug
                    tags
                    reading_time
                }
            }
        }
    `);
    const [doorsToDisplay, setDoorsToDisplay] = useState([]);
    const [numberOfPages, setNumberOfPages] = useState(1);
    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const searchParamsValues = useMemo(
        () => Array.from(new Set(searchParams.keys())).map((key) => ({ name: key, value: searchParams.getAll(key) })),
        [searchParams]
    );

    const dispatch = useAppDispatch();
    const getFilteredDoors = useCallback(
        (filters, type) => {
            const doors = type === 'klamki' ? data.allAccessories.nodes : data.allDoors.nodes;
            const filtered = doors.filter((door) => {
                if (brand && brand !== 'klamki' && generateSlug(door.lines.brands.type) !== brand) return false;
                const filterMap = filters.map((filter) => {
                    if (!filter) return true;
                    const values = Array.isArray(filter.nestedPath)
                        ? filter.nestedPath.map((p) => _.get(door, p)).flat()
                        : _.get(door, filter.nestedPath);
                    if (values === undefined) return false;
                    const mappedFilter = filter.value.map((val) => generateSlug(val));
                    if (!Array.isArray(values)) return filter.comparator(mappedFilter, generateSlug(values.toString()));
                    if (!filter.key) {
                        return mappedFilter.every((filterVal) => filter.comparator(
                            values.map((val) => generateSlug(val.toString())),
                            filterVal
                        ));
                    }
                    const mappedValues = values.map((val) => generateSlug(_.get(val, filter.key)?.toString()));
                    const filterValueExistsInDoor = mappedFilter.every((filterVal) => filter.comparator(mappedValues, filterVal));
                    return filterValueExistsInDoor;
                });
                return filterMap.every((val) => val);
            });
            return filtered;
        },
        [data]
    );

    useEffect(() => {
        const [searchSorters, searchFilters] = _.partition(searchParamsValues, { name: 'sorting' });
        const allFilters = [...searchFilters, friendlyFilter]
            .filter((f) => f)
            .map((filter) => ({
                name: filter.name,
                value: Array.isArray(filter.value) ? [...filter.value] : [filter.value],
                nestedPath: getFilterGraphQLPath(filter.name),
                key: getFilterGraphQLKey(filter.name),
                comparator: getFilterGraphQLComparator(filter.name)
            }));
        const filteredDoors = getFilteredDoors(allFilters, brand).reverse();
        setNumberOfPages(Math.ceil(filteredDoors.length / DOORS_PER_PAGE));
        const sorter = searchSorters.find((s) => s.name === 'sorting');
        const sortedDoors = sorter ? filteredDoors.sort(getSortingFunction(sorter.value[0])) : filteredDoors;
        const pageFilter = allFilters.find((f) => f.name === 'page')?.value[0];
        const paginatedDoors = sortedDoors.slice(
            (pageFilter - 1 || 0) * DOORS_PER_PAGE,
            ((pageFilter - 1 || 0) + 1) * DOORS_PER_PAGE
        );
        setDoorsToDisplay(paginatedDoors);
    }, [searchParamsValues, friendlyFilter]);

    useEffect(() => {
        const getAvailableFilters = () => {
            const filterSet = {
                brands: ['Barański Premium', 'Barański Optimo', 'Barański Smart'],
                bestsellers: ['Bestsellers'],
                lines: new Set(),
                kinds: new Set(),
                finishes: new Set(),
                colors: new Set(),
                styles: new Set(),
                glazes: new Set(),
                constructions: new Set(),
                fittings: new Set(),
                destinations: new Set(),
                handles: new Set(),
                knobColors: new Set(),
                knobStyles: new Set()
            };
            const doors = brand === 'klamki' ? data.allAccessories.nodes : data.allDoors.nodes;
            if (brand === 'klamki') {
                doors.forEach((door) => {
                    const knobColors = door.filter_color;
                    const knobStyles = door.filter_style;
                    const handles = door.handle_type;
                    const filters = {
                        knobColors,
                        knobStyles,
                        handles
                    };
                    Object.keys(filters).forEach((filter) => {
                        filters[filter].forEach((f) => filterSet[filter].add(f.toLowerCase().trim()));
                    });
                });
            } else {
                doors.forEach((door) => {
                    const lines = [door.lines.name];
                    const colors = [
                        ...door.variants.map((c) => c.color.color_filter_name),
                        ...door.custom_variants.map((c) => c.color.color_filter_name)
                    ];
                    const finishes = door.lines.finishing.map((f) => f.name);
                    const destinations = door.purposes.map((p) => p.name);
                    const {
                        kind, style, glaze, fitting, construction
                    } = door;
                    const filters = {
                        lines,
                        kinds: kind,
                        finishes,
                        colors,
                        styles: style,
                        glazes: glaze,
                        constructions: construction,
                        fittings: fitting,
                        destinations
                    };
                    if (!brand || brand === generateSlug(door.lines.brands.type)) {
                        Object.keys(filters).forEach((filter) => {
                            filters[filter].forEach((f) => filterSet[filter].add(f?.toLowerCase()?.trim()));
                        });
                    }
                });
            }

            const filterArr = Object.keys(filterSet).reduce(
                (acc, key) => ({
                    ...acc,
                    [key]: key === 'colors' ? Array.from(filterSet[key]).sort() : Array.from(filterSet[key])
                }),
                {}
            );
            dispatch(setAvailableFilters(filterArr));
        };
        getAvailableFilters();
    }, [brand, data, dispatch]);

    return (
        <>
            <ContentView
                doors={ doorsToDisplay }
                numberOfPages={ numberOfPages }
                articles={ data.allArticles.nodes.filter((a) => a.tags?.map((t) => generateSlug(t)).includes(brand)) }
                title={ title }
            />
        </>
    );
};

Content.propTypes = {
    brand: PropTypes.string,
    friendlyFilter: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        nestedPath: PropTypes.string,
        key: PropTypes.string
    }),
    title: PropTypes.string,
    location: PropTypes.shape({ pathname: PropTypes.string, search: PropTypes.string }).isRequired
};

Content.defaultProps = {
    brand: undefined,
    friendlyFilter: undefined,
    title: undefined
};
